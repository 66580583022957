.loader-intro {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.text-focus-in {
	-webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.tracking-out-contract-bck-bottom {
	-webkit-animation: tracking-out-contract-bck-bottom 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: tracking-out-contract-bck-bottom 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation-delay: 6s
}

 @-webkit-keyframes tracking-out-contract-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px) translateY(300px);
            transform: translateZ(-500px) translateY(300px);
    opacity: 0;
  }
}
@keyframes tracking-out-contract-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px) translateY(300px);
            transform: translateZ(-500px) translateY(300px);
    opacity: 0;
  }
}
